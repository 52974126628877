import React, {Dispatch, FC, useEffect, useState} from "react";
import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState,ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import UploadFile from "./UploadFile";
import {PrimaryButton} from "../../shared/buttons/igemo_button";


interface setupTabStruct {
    handlePayloadChange: Dispatch<any>
    handleUpdate: Dispatch<any>
    setupPayload: setupPayloadStruct
    handleColorPayloadChange: Dispatch<any>
    errors:any
}

interface setupPayloadStruct {
    title: string,
    text: string,
    logo: string,
    favicon: string,
    titleColor: string,
    textColor: string,
    primaryColor: string,
    secondaryColor: string,
    backgroundColor: string,
    goodOfferTitle: string,
    goodOfferText: string,
    partnerLogos: any
    removePartnerLogoIds: any
    partnerLogosReviewOnly: any
}

interface ColorPickerComponentProps {
    initialColor?: string,
    handlePayloadChange?: (data: any) => void,
    selectedColorPalette?: string
}

const ColorPickerComponent = ({ initialColor = "#000", handlePayloadChange = () => {}, selectedColorPalette = "" }: ColorPickerComponentProps) => {
    const [color, setColor] = useColor(initialColor);

    const handleOnColorChange = (value: any) => {
        setColor(value);
        handlePayloadChange({ key: selectedColorPalette, value: value?.hex });
    };

    return (
        <ColorPicker
            color={color}
            onChange={handleOnColorChange}
        />
    );

}

const SetupPage: FC<setupTabStruct> = ({
    setupPayload,
    handleUpdate,
    handlePayloadChange,
    handleColorPayloadChange,
    errors
}) => {
    const [color, setColor] = useColor("#561ecb");
    const [selectedColorPalette, toggleColorPalette] = useState('titleColor');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    console.log(setupPayload);

    const handleOnChange = (event: any, key: string) => {
        const { value } = event.target;
        handlePayloadChange({ key, value});
    };

    const setEditorStateAndValue = (state: any) => {
        setEditorState(state);
    }

    useEffect(()=> {
        const current = convertToRaw(editorState.getCurrentContent());
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        handlePayloadChange({ key:'goodOfferText', value});
    }, [editorState])

    useEffect(()=> {
        const contentBlock = convertFromHTML(setupPayload?.goodOfferText ? setupPayload?.goodOfferText : '');
        // @ts-ignore
        const contentState = ContentState.createFromBlockArray(contentBlock);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
    }, [])

    // const textEditorData = (event: any, key: string) => {
    //     let { value } = event.target;
    //     const parser = new DOMParser();
    //     const dom = parser.parseFromString(value, "text/html");
    //     const links = dom.getElementsByTagName('a');
    //     const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    //     // @ts-ignore
    //     for (let item of links) {
    //         !item.hasAttribute('target') && item.setAttribute('target', '_blank');
    //         item.getAttribute('href').match(/^(http|https):\/\/?[a-d]/);
    //         const test = urlRegex.test(item.getAttribute('href'));
    //         if(test){
    //             item.setAttribute('href', `https://${item.getAttribute('href')}`)
    //         }
    //     }
    //     value = dom.getElementsByTagName('body')[0].innerHTML;
    //     handlePayloadChange({ key, value});
    // };

    const handleImageChange = (key: string, value: any) => {
        if (key === 'partnerLogos' && value?.isLogos) {
            const updatedPartners = [...setupPayload?.partnerLogos];
            updatedPartners?.push({
                "logo": value?.response,
                "name": value?.name,
                uid: value?.uid
            });
            handlePayloadChange({ key, value: updatedPartners });
        } else if (key === 'partnerLogos' && value?.isRemove) {
            const updatedPartners = [...setupPayload?.removePartnerLogoIds];
            updatedPartners?.push(value?.isRemove);
            handlePayloadChange({ key: 'removePartnerLogoIds', value: updatedPartners });
        }
        else if (key === 'partnerLogos') {
            const updatedPartners = [...setupPayload?.partnerLogos];
            const newUpdatedPartners: any = [];
            updatedPartners.forEach((partner: any) => {
                if(partner?.id) {
                    newUpdatedPartners.push(partner);
                }
                else {
                    if(partner?.name !== value?.response?.name) {
                        newUpdatedPartners.push(partner);
                    }
                }
            })
            handlePayloadChange({ key, value: newUpdatedPartners });
        } 
        else {
            handlePayloadChange({ key, value: value?.response });
        }
    };

    const getDefaultImageView = (image: any, key: string) => {
        const list: any = [];
        if (key === 'partnerLogos' && image?.length) {
            image?.forEach((i: any) => {
                list.push({
                    uid: i?.id || null,
                    b_id: i?.id || null,
                    name: i?.name,
                    status: 'done',
                    url: `${process.env.REACT_APP_STORAGE_URL}/${i?.logo}`,
                });
            });
        } else {
            list.push({
                uid: image?.id || null,
                name: image?.name,
                status: 'done',
                url: `${process.env.REACT_APP_STORAGE_URL}/${image}`,
            });
        }
        return list;
    };

    return(
        <>
            <div className="border-grey-300 border-t-[1px] border-b-[1px]">
                <div className="px-12 py-6 pb-[8px]">
                    <div className="lg:col-span-2 w-full mb-6 relative font-sans text-[14px] font-semibold text-[#4F4C4C]/[0.22]">
                        LOGO
                    </div>
                    <div className="flex">
                        <div style={{ flex: '1 1 0' }}>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">LOGO GEMEENTE <span className='text-error'>*</span></p>
                            
                            <div style={{ borderRadius: 10 }}>
                                <UploadFile
                                    handleOnChange={(val) => handleImageChange('logo', val)}
                                    imageFile={setupPayload?.logo ? getDefaultImageView(setupPayload?.logo, 'logo') : []}
                                    maxCount={1}
                                />
                                <span className="text-error text-xs">{errors.logo ? errors.logo : null}</span>
                            </div>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">FAVICON LOGO <span className='text-error'>*</span></p>
                            <div>
                                <UploadFile
                                    handleOnChange={(val) => handleImageChange('favicon', val)}
                                    imageFile={setupPayload?.favicon ? getDefaultImageView(setupPayload?.favicon, 'favicon') : []}
                                    maxCount={1}
                                />
                                <span className="text-error text-xs">{errors.favicon ? errors.favicon : null}</span>
                            </div>
                        </div>
                        <div style={{ flex: '3 3 0'  }}>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">PARTNER LOGO'S</p>
                            <div>
                                <UploadFile
                                    handleOnChange={(val) => handleImageChange('partnerLogos', val)}
                                    imageFile={(setupPayload?.partnerLogos && setupPayload?.partnerLogos?.length) > 0 ? getDefaultImageView(setupPayload?.partnerLogos, 'partnerLogos') : []}
                                    maxCount={100}
                                    onlyForPreviewImages={(setupPayload?.partnerLogosReviewOnly && setupPayload?.partnerLogosReviewOnly?.length > 0) ? getDefaultImageView(setupPayload?.partnerLogosReviewOnly, 'partnerLogos') : []}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="border-grey-300 border-t-[1px] border-b-[1px]">
                <div className="px-12 py-6 pb-[8px]">
                    <div className="lg:col-span-2 w-full mb-6 relative font-sans text-[14px] font-semibold text-[#4F4C4C]/[0.22]">
                        HOMEPAGE
                    </div>
                    <div className="flex">
                        <div style={{ flex: '1 1 0', marginRight: 25 }}>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">TITEL <span className='text-error'>*</span></p>
                            <div className="" style={{ borderRadius: 10 }}>
                                <textarea
                                    onChange={(val) => handleOnChange(val, 'title')}
                                    style={{ width: '100%', resize: 'none' }}
                                    value={setupPayload?.title}
                                    className="border-[1px] box-border p-[10px]"
                                    name="title"
                                    rows={5}
                                />
                            </div>
                            <span className="text-error text-xs">{errors.title ? errors.title : null}</span>
                        </div>
                        <div style={{ flex: '3 3 0'  }}>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">BESCHRIJVING <span className='text-error'>*</span></p>
                            <div>
                                <textarea
                                    onChange={(val) => handleOnChange(val, 'text')}
                                    style={{ width: '100%', resize: 'none' }}
                                    value={setupPayload?.text}
                                    className="border-[1px] box-border p-[10px]"
                                    name="text"
                                    rows={10}
                                />
                            </div>
                            <span className="text-error text-xs">{errors.text ? errors.text : null}</span>
                        </div>

                    </div>
                </div>
            </div>

            <div className="border-grey-300 border-t-[1px] border-b-[1px]">
                <div className="px-12 py-6 pb-[8px]">
                    <div className="lg:col-span-2 w-full mb-6 relative font-sans text-[14px] font-semibold text-[#4F4C4C]/[0.22]">
                        GOODOFFER
                    </div>
                    <div className="flex">
                        <div style={{ flex: '1 1 0', marginRight: 25 }}>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">TITEL</p>
                            <div className="" style={{ borderRadius: 10 }}>
                                <textarea
                                    onChange={(val) => handleOnChange(val, 'goodOfferTitle')}
                                    style={{ width: '100%', resize: 'none' }}
                                    value={setupPayload?.goodOfferTitle}
                                    className="border-[1px] box-border p-[10px]"
                                    name="goodOfferTitle"
                                    rows={5}
                                />
                            </div>
                            <span className="text-error text-xs">{errors.goodOfferTitle ? errors.goodOfferTitle : null}</span>
                        </div>
                        <div style={{ flex: '3 3 0'  }}>
                            <p className="text-[14px] font-sans primary-text font-semibold pb-2">BESCHRIJVING</p>
                            <div className="[&_a]:text-primary [&_a]:underline">
                                <Editor
                                    toolbar={{
                                        link: {
                                            defaultTargetOption: '_blank'
                                        }
                                    }}
                                    editorState={editorState}
                                    onEditorStateChange={(state)=> setEditorStateAndValue(state)}
                                    editorClassName="border-[1px] box-border p-[10px] h-[200px]"
                                />
                                {/*<textarea*/}
                                {/*    onChange={(val) => handleOnChange(val, 'goodOfferText')}*/}
                                {/*    style={{ width: '100%', resize: 'none' }}*/}
                                {/*    value={setupPayload?.goodOfferText}*/}
                                {/*    className="border-[1px] box-border p-[10px]"*/}
                                {/*    name="goodOfferText"*/}
                                {/*    rows={10}*/}
                                {/*/>*/}
                            </div>
                            <span className="text-error text-xs">{errors.goodOfferText ? errors.goodOfferText : null}</span>
                        </div>

                    </div>
                </div>
            </div>

            <div className="border-grey-300 border-t-[1px] border-b-[1px]">
                <div className="px-12 py-6 pb-[8px]">
                    <div className="lg:col-span-2 w-full mb-6 relative font-sans text-[14px] font-semibold text-[#4F4C4C]/[0.22]">
                        KLEUREN
                    </div>
                    <div className="flex">
                        <div style={{ flex: '3 3 0', marginRight: 25 }}>
                            <div className=" grid grid-cols-4 gap-x-2">
                                {/* <div>
                                    <p className="text-[14px] font-sans text-[#0C3C21] font-semibold pb-2">TITEL</p>
                                    <div className="cursor-pointer border-[1px] border-black"
                                        style={{ borderRadius: 4, width: 50, height: 50, background: `${setupPayload?.titleColor}` || '#561ecb' }}
                                        onClick={() => toggleColorPalette('titleColor')} />
                                </div>

                                <div>
                                  <p className="text-[14px] font-sans text-[#0C3C21] font-semibold pb-2">TEKST</p>
                                    <div className="cursor-pointer border-[1px] border-black"
                                         style={{ borderRadius: 4, width: 50, height: 50, background: `${setupPayload?.textColor}` || '#561ecb' }}
                                         onClick={() => toggleColorPalette('textColor')} />
                                </div> */}

                                <div>
                                    <p className="text-[14px] font-sans primary-text font-semibold pb-2">PRIMAIRE KLEUR <span className='text-error'>*</span></p>
                                    <div className="cursor-pointer border-[1px] border-black"
                                         style={{ borderRadius: 4, width: 50, height: 50, background: `${setupPayload?.primaryColor}` || '#561ecb' }}
                                         onClick={() => toggleColorPalette('primaryColor')} />
                                </div>

                                <div>
                                  <p className="text-[14px] font-sans primary-text font-semibold pb-2">SECUNDAIRE KLEUR <span className='text-error'>*</span></p>
                                    <div className="cursor-pointer border-[1px] border-black"
                                         style={{ borderRadius: 4, width: 50, height: 50, background: `${setupPayload?.secondaryColor}` || '#561ecb' }}
                                         onClick={() => toggleColorPalette('secondaryColor')} />
                                </div>

                                <div>
                                  <p className="text-[14px] font-sans primary-text font-semibold pb-2">ACHTERGROND <span className='text-error'>*</span></p>
                                    <div className="cursor-pointer border-[1px] border-black"
                                        style={{ borderRadius: 4, width: 50, height: 50, background: `${setupPayload?.backgroundColor}` || '#561ecb' }}
                                        onClick={() => {toggleColorPalette('backgroundColor')}} />
                                </div>
                            </div>
                        </div>
                        {/* declaring same component multiple times to force re-render so initial values can be loaded, built in hook for component does not allow for dynamically changing selected values */}
                        <div style={{ flex: '1 1 0'  }}>
                            {selectedColorPalette === "titleColor" && (
                                <ColorPickerComponent
                                    initialColor={setupPayload?.titleColor || '#561ecb'}
                                    handlePayloadChange={handleColorPayloadChange}
                                    selectedColorPalette={selectedColorPalette}
                                />
                            )}
                            {selectedColorPalette === "textColor" && (
                                <ColorPickerComponent
                                    initialColor={setupPayload?.textColor || '#561ecb'}
                                    handlePayloadChange={handleColorPayloadChange}
                                    selectedColorPalette={selectedColorPalette}
                                />
                            )}
                            {selectedColorPalette === "primaryColor" && (
                                <ColorPickerComponent
                                    initialColor={setupPayload?.primaryColor || '#561ecb'}
                                    handlePayloadChange={handleColorPayloadChange}
                                    selectedColorPalette={selectedColorPalette}
                                />
                            )}
                            {selectedColorPalette === "secondaryColor" && (
                                <ColorPickerComponent
                                    initialColor={setupPayload?.secondaryColor || '#561ecb'}
                                    handlePayloadChange={handleColorPayloadChange}
                                    selectedColorPalette={selectedColorPalette}
                                />
                            )}
                            {selectedColorPalette === "backgroundColor" && (
                                <ColorPickerComponent
                                    initialColor={setupPayload?.backgroundColor || '#561ecb'}
                                    handlePayloadChange={handleColorPayloadChange}
                                    selectedColorPalette={selectedColorPalette}
                                />
                            )}
                        </div>

                    </div>
                </div>
            </div>

            <div className="px-8">
                <div className="flex justify-end mt-4">
                    <PrimaryButton content="Opslaan" onClick={() => handleUpdate(undefined)}/>
                </div>
            </div>
        </>
    )
};

export default SetupPage;
