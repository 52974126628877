import React, { FC, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// layout
import Layout from "../../components/layout";
import SectionForm from "../../components/homepage/sectionForm";
import SectionSteps from "../../components/homepage/sectionSteps";
import SectionPros from "../../components/homepage/sectionPros";
import SectionH2h from "../../components/homepage/sectionHome2home";

interface HomePageInterface {
  
}

const HeaderFilter: FC<HomePageInterface> = ({}) => {
  // const refH2h = useRef();
  const navigate = useNavigate();

  const localKeys = [
    "localState",
    "energyValues",
    "parameters",
    "pdfData",
    "result",
    "currentPayload",
    "maximumbedragEnergielening",
    "SET_HEAT_LOSS_DATA",
    "simulationInitDone",
    "standardDimensions",
    // "newState",
    "energyGasNew",
    "improvedPayload",
    // "street",
    // "city"
  ]
  
  
  useEffect(() => {
    const result = JSON.parse(localStorage.getItem("SET_HEAT_LOSS_DATA" || "{}")!);
    if(result) {
      // removeLocalStorageItems().then(() => {
      //   removeCookies().then(() => {
      //     navigate(0);
      //   })
      // })
    }
  }, []);



  useEffect(() => {
    removeLocalStorageItems().then(() => {
        removeCookies().then(() => {
        // navigate(0);
        //console.log("cookie and storage clean")
        })
    })
}, []);

const removeLocalStorageItems = async (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
    try {
        //console.log("clear all")
        const auth = localStorage.getItem("auth") || "";
        const domainObj = localStorage.getItem("domainObj") || "";
        const theme = localStorage.getItem("theme") || "";
        localStorage.clear()
        localStorage.setItem("auth",auth)
        localStorage.setItem("domainObj",domainObj)
        localStorage.setItem("theme",theme)
        // localKeys.forEach((key) => {
        // localStorage.removeItem(key);
        // });
        resolve();
    } catch (error) {
        reject(error);
    }
    });
};

const removeCookies = async (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
    try {
        // Object.keys(Cookies.get()).forEach((cookieName) => {
        //   Cookies.remove(cookieName, { path: '/' });
        // });
        Cookies.remove("sessionData", { path: '/' });
        Cookies.remove("userId", { path: '/' });

        resolve();
    } catch (error) {
        reject(error);
    }
    });
};


  return (
    <Layout>
      <>
        <SectionForm />
        {/* <SectionSteps />
        <SectionPros /> */}
        {/* <SectionH2h /> */}
      </>
    </Layout>
  );
};

export default HeaderFilter;
