import {create} from 'zustand';

interface LabelStore {
  labelOpen: boolean
  setLabel: (value: boolean) => void;
}

const useLabelStore = create<LabelStore>((set) => ({
  labelOpen: false,
  setLabel: (value: boolean) => set({ labelOpen: value }),
}));

export default useLabelStore;
