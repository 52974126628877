import React, { FC, useState, useEffect } from "react";
// import Header from "./header";
import Header from "./headerNew";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import Cookies from "./cookies";
// cookies package
import { useCookieConsent } from "use-cookie-consent";
import DomainService from "../../api/Domain"
import DomainConfigService from "../../api/DomainConfig"
import useTheme from "../../context/globalContext/useTheme";

interface LayoutInterface {
  children: JSX.Element | JSX.Element[];
  disableFooter?: boolean;
  openPasswordResetModal?: boolean;
  openloginModal?: boolean;
  setOpenLoginModal?: (isOpenLoginModal: boolean) => void;
  backgroundColor?: string;
}

const Layout: FC<LayoutInterface> = ({
  children,
  disableFooter = false,
  openPasswordResetModal = false,
  openloginModal = false,
  setOpenLoginModal = () => {},
  backgroundColor = "white"
}) => {
  const [headerState, setHeaderState] = useState({
    isLoggedIn: false,
    isHouseOwner: false,
    isAdmin: false,
    isSuperadmin: false
  });

  // useCookie options
  const { consent, acceptAllCookies, acceptCookies } = useCookieConsent();
  const themeLocal = JSON.parse(localStorage.getItem("theme") || "{}");
  // state to show and hide cookie
  const [cookieBanner, setCookieBanner] = useState("");
  const [ theme, setTheme ] = useState({
    backgroundColor: themeLocal.backgroundColor || "#F9F9F9",
    primaryColor: themeLocal.primaryColor || "#298752",
    secondaryColor: themeLocal.secondaryColor || "white",
    textColor: "#000",
    titleColor: "#000",
  });
  const [ logoUrl, setLogoUrl ] = useState("");
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  const location = useLocation();

  useEffect(() => {
    if (auth.uid) {
      // this means a user is logged in
      const expired = hasLoginExpired();
      if (expired) {
        localStorage.removeItem("auth");
        setHeaderState({
          isLoggedIn: false,
          isHouseOwner: false,
          isAdmin: false,
          isSuperadmin: false
        });
      } else {
        if (auth.typeId === 1) {
          // this means a user is a house owner
          setHeaderState({
            isLoggedIn: true,
            isHouseOwner: true,
            isAdmin: false,
            isSuperadmin: false
          });
        } else if (auth.typeId === 3) {
          // this means a user is an igemo user
          setHeaderState({
            isLoggedIn: true,
            isHouseOwner: false,
            isAdmin: true,
            isSuperadmin: false
          });
        } else if(auth.typeId === 4) {
          setHeaderState({
            isLoggedIn: true,
            isHouseOwner: false,
            isAdmin: true,
            isSuperadmin: true
          });
        }
         else if (auth.typeId === 2 || auth.typeId === 5) {
          // this means a user is a contractor
          setHeaderState({
            isLoggedIn: true,
            isHouseOwner: false,
            isAdmin: false,
            isSuperadmin: false
          });
        }
      }
    }
  }, [location.pathname]);

  const hasLoginExpired = () => {
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const now = new Date();
    const expiry = new Date(auth.expireIn * 1000);
    return now > expiry;
  };

  // useEffect for cookies
  useEffect(() => {
    if (Object.keys(consent).length > 0) {
      setCookieBanner("false");
    } else {
      setCookieBanner("true");
    }
  }, [consent]);

  // method for simulations and offer-request page to add full height and to add background color.
  const classHandler = () => {
    if (
      location.pathname === "/simulations" ||
      location.pathname === "/offer-request"
    ) {
      return "bg-[#f0f5f5]";
    } else {
      return "";
    }
  };

  useEffect(()=> {
    const hostname = window.location.hostname;
    const localTheme = JSON.parse(localStorage.getItem("theme") || "{}");
    // check to see if object has atleast one valid value
    if(localTheme?.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', localTheme.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', localTheme.secondaryColor);
      document.documentElement.style.setProperty('--background-color', localTheme.backgroundColor);
    }
    const length = hostname.split('.').length;
    const subDomain = hostname.split('.')[0];
    if(hostname==='localhost') return;
    (async ()=>{
      const response = await DomainService.getDomainObj(subDomain ? subDomain : '')
      if(response.data?.data == null && subDomain !== 'frontend' && length > 2){
        window?.location?.replace(window?.location?.toString()?.replace(subDomain + '.', ''));
      }
      const _domainObj = response.data?.data
      // incase the both subdomains have boht localstorage bucket on browser
     // const favicon = localStorage.getItem("favicon");
      localStorage.setItem("domainObj", JSON.stringify(_domainObj))
      const domainId = _domainObj?.id;
      if(domainId == undefined) return;
      const resData = await DomainConfigService.getDomainObj(domainId)
      const domainTheme = resData.data?.data?.data[0] || {}
      const theme = {
        backgroundColor: domainTheme?.backgroundColor,
        primaryColor: domainTheme?.primaryColor,
        secondaryColor: domainTheme?.secondaryColor,
        textColor: domainTheme?.textColor,
        titleColor: domainTheme?.titleColor,
      }
      localStorage.setItem("goodOffer", JSON.stringify({
        title: domainTheme?.goodOfferTitle,
        text: domainTheme?.goodOfferText
      }))
      const faviconUrl = process.env.REACT_APP_STORAGE_URL +"/" + domainTheme.favicon;
      localStorage.setItem("favicon", faviconUrl);
      const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = faviconUrl;
      document.getElementsByTagName('head')[0].appendChild(link);
      // Recommended to store it in global store too
      localStorage.setItem("theme", JSON.stringify(theme))
      setLogoUrl(domainTheme?.logo)
      setTheme(theme)
    })()
  }, [])

  useEffect(() => {
    if(theme) {
      document.documentElement.style.setProperty('--primary-color', theme.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor);
      document.documentElement.style.setProperty('--background-color', theme.backgroundColor);
    }
  }, [theme])

  return (
    <div className="relative base-page" style = {{backgroundColor: theme.backgroundColor}}>
      <Header
        isLoggedIn={headerState.isLoggedIn}
        isAdmin={headerState.isAdmin}
        isHouseOwner={headerState.isHouseOwner}
        isSuperadmin = {headerState?.isSuperadmin}
        openPasswordResetModal={openPasswordResetModal}
        openLoginModal={openloginModal}
        setOpenLoginModal={setOpenLoginModal}
        logo = {logoUrl}
      />
      <div className={`main-area ${classHandler()}`} style = {{backgroundColor: theme.backgroundColor}}>
        <div className="flex flex-wrap flex-col min-h-[calc(100vh-254px)]">
          <main className="background-main w-full h-full min-h-[calc(100vh-254px)]">{children}</main>
        </div>
        {!disableFooter && <Footer />}
      </div>
       {/*cookies*/}
      {/*{cookieBanner === "true" && (*/}
      {/*  <Cookies*/}
      {/*    setCookieBanner={setCookieBanner}*/}
      {/*    acceptAllCookies={acceptAllCookies}*/}
      {/*    acceptCookies={acceptCookies}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default Layout;
