import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layout";
import SetupService from "../../api/Setup";
import SetupPageTab1 from "./SetupPageTab1";
import SetupPageTab2 from "../organizations/FormStep2";
import {message} from "antd";
import axios from "axios";
import Joi from "joi";

type DomainType = {
    id: string;
    name: string;
    subdomain: string;
    domain: string;
    adminEmail: string;
    status: boolean;
    createdAt: string;
    updatedAt: null | string;
    deletedAt: null | string;
    partners: any[]; // Replace any with the actual type of the partners if known
};

type DataType = {
    id: string;
    domainId: string;
    title: string;
    text: string;
    logo: string;
    favicon: string;
    titleColor: string;
    textColor: string;
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    goodOfferTitle: string;
    goodOfferText: string;
    createdAt: string;
    updatedAt: null | string;
    deletedAt: null | string;
    domain: DomainType;
  };

const setupPageSchema = Joi.object({
    title: Joi.string()
        .required()
        .messages({
            'string.empty': `Titel mag niet leeg zijn.`,
            'any.required': `Titel is een verplicht veld.`
        }),
    text: Joi.string()
        .required()
        .messages({
            'string.empty': `Beschrijving mag niet leeg zijn.`,
            'any.required': `Beschrijving is een verplicht veld.`
        }),
    goodOfferTitle: Joi.optional(),
        //.required()
        // .messages({
        //     'string.empty': `De titel van een goede aanbieding mag niet leeg zijn.`,
        //     'any.required': `Titel van goede aanbieding is een verplicht veld.`
        // }),
    goodOfferText: Joi.optional(),
        //.required()
        // .messages({
        //     'string.empty': `Een goede aanbiedingsbeschrijving mag niet leeg zijn.`,
        //     'any.required': `Een goede aanbiedingsbeschrijving is een verplicht veld.`
        // }),
    logo: Joi.string()
        .required()
        .messages({
            'string.empty': `Logo mag niet leeg zijn.`,
            'any.required': `Logo is een verplicht veld.`,
            'string.base': `Logo is een verplicht veld.`
        }),
    favicon: Joi.string()
        .required()
        .messages({
            'string.empty': `Favicon mag niet leeg zijn.`,
            'any.required': `Favicon is een verplicht veld.`,
            'string.base': `Favicon is een verplicht veld.`
        }),
    partnerLogos: Joi.optional()
})

const SetupPage = () => {
    const [formStep, setFormStep] = useState('1');
    const [isLoading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [domainConfigData, setDomainConfigData] = useState();
    const [provincesList, setProvincesList] = useState([]);
    const [editOrganization, toggleEditOrganization] = useState('');
    const [setupPayload, setSetupPayload] = useState({
        "domainId": "3",
        "title": "",
        "text": "",
        "logo": "",
        "favicon": "",
        "titleColor": "",
        "textColor": "",
        "primaryColor": "",
        "secondaryColor": "",
        "backgroundColor": "",
        "goodOfferTitle": "",
        "goodOfferText": "",
        "partnerLogos": [],
        "partnerLogosReviewOnly": [],
        "removePartnerLogoIds": [],
    });
    const [ domainData, setDomainData ] = useState<DataType | null>(null);
    const [ refreshState, setRefreshState ] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [errors, setErrors] = useState({
        title:"",
        text:"",
        favicon:"",
        logo:""
    })
    const mainAdminData = JSON.parse(localStorage.getItem('auth') || '{}');
    const mainDomainObj = JSON.parse(localStorage.getItem("domainObj") || 'null')
    const navigate = useNavigate();
    const onlyForPreviewImageNames: string[] = ["main-logo"]

    useEffect(() => {
        fetchPageDetails();
    }, [refreshState]);

    useEffect(() => {
        const adminDetails = JSON.parse(localStorage.getItem('auth') || '{}');
        const domainObj = JSON.parse(localStorage.getItem("domainObj") || 'null')
        if((adminDetails?.typeId == 3 || adminDetails?.typeId == 4) && domainObj?.id) {
            axios.get(process.env.REACT_APP_BACKEND_URL + '/domain-config/domain/' + domainObj?.id).then((response) => {
                setDomainData(response.data.data.data[0])
            }).catch(error => console.log(error))
        }
    }, [])

    const fetchPageDetails = async () => {
        try {
            const adminDetails = JSON.parse(localStorage.getItem('auth') || '{}');
            const domainObj = JSON.parse(localStorage.getItem("domainObj") || 'null')
            if((adminDetails?.typeId == "3" || adminDetails?.typeId == "4")) {
                setLoading(true)
                const response = await SetupService.getDomainDetails(domainObj?.id || "1");
                const {data} = response?.data;
                setDomainConfigData(data[0]);
                const partnerLogosReviewOnly = data[0]?.domain?.partners?.filter((logo: any) => onlyForPreviewImageNames.includes(logo.name))
                const editablePartnerLogos = data[0]?.domain?.partners?.filter((logo: any) => !!!onlyForPreviewImageNames.includes(logo.name))

                setSetupPayload({
                    "domainId": data[0]?.domainId,
                    "title": data[0]?.title,
                    "text": data[0]?.text,
                    "logo": data[0]?.logo,
                    "favicon": data[0]?.favicon,
                    "titleColor": data[0]?.titleColor,
                    "textColor": data[0]?.textColor,
                    "primaryColor": data[0]?.primaryColor,
                    "secondaryColor": data[0]?.secondaryColor,
                    "backgroundColor": data[0]?.backgroundColor,
                    "goodOfferTitle": data[0]?.goodOfferTitle,
                    "goodOfferText": data[0]?.goodOfferText,
                    "partnerLogos": editablePartnerLogos || [],
                    "removePartnerLogoIds": [],
                    "partnerLogosReviewOnly": partnerLogosReviewOnly || []
                })
                setLoading(false);
            }
            else {
                // navigate to home since the account logged is not admin
                // navigate('/');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (closeForm?: boolean) => {
        if (closeForm) {
            toggleEditOrganization('');

        }
    };

    const handlePayloadChange = (data:any) => {
        const {key, value} = data;
        try {
            if(["logo",'favicon','removePartnerLogoIds'].includes(key) && typeof value ==="object"){
                setSetupPayload({ ...setupPayload, [key]: value });
            }else{
                const target = setupPageSchema.extract(key);
                const result = target.validate(value)
                if (result.error && isSubmitted) {
                    setErrors({ ...errors, [key]: result.error.message })
                }
                else {
                    setErrors({ ...errors, [key]: "" })
                }
                setSetupPayload({ ...setupPayload, [key]: value });
            }
        } catch (error: any) {
            if (isSubmitted) {
                setErrors({ ...errors, [key]: error.message });
            }
        }
    };

    const handleColorPayloadChange = (data:any) => {
        const {key, value} = data;
        setSetupPayload({ ...setupPayload, [key]: value });
    };

    const handleUpdate = async () => {
        try {
            setIsSubmitted(true);
            setLoading(true);
            const updatedPayload = {...setupPayload};
            const results = setupPageSchema.validate({
                text:updatedPayload.text,
                title: updatedPayload.title,
                logo: updatedPayload.logo,
                favicon: updatedPayload.favicon,
                //goodOfferTitle: updatedPayload.goodOfferTitle,
                //goodOfferText: updatedPayload.goodOfferText,
            } , {abortEarly:false})
            if (results.error) {
                let errObj ={...errors}
                for (const err of results.error.details) {
                    errObj = {...errObj,[err.path[0]]:err.message}
                    messageApi.open({
                        type: 'error',
                        content: err.message,
                        duration: 3,
                    });
                }
                setErrors(errObj);
                return false
            }
            const domainId = domainData?.id.toString() || "1";
            console.log({updatedPayload})
            let updatedPartnersLogos: any = [...updatedPayload?.partnerLogos];
            const adminDetails = JSON.parse(localStorage.getItem('auth') || '{}');
            const domainObj = JSON.parse(localStorage.getItem("domainObj") || 'null')
            updatedPartnersLogos = updatedPartnersLogos?.map((p: any) => {
                return {
                    "domainId": p?.domainId ? p?.domainId : domainObj?.id || "1",
                    "logo": p?.logo,
                    "name": p?.name,
                    ...(p?.id && { id: p?.id }),
                };
            });
            if(adminDetails?.typeId == "3" || adminDetails?.typeId == "4" && domainId) {
                const payload = {
                    "domainId": domainObj?.id || "1",
                    "title": updatedPayload?.title,
                    "text": updatedPayload?.text,
                    "logo": updatedPayload?.logo,
                    "favicon": updatedPayload?.favicon,
                    "titleColor": updatedPayload?.titleColor,
                    "textColor": updatedPayload?.textColor,
                    "primaryColor": updatedPayload?.primaryColor,
                    "secondaryColor": updatedPayload?.secondaryColor,
                    "backgroundColor": updatedPayload?.backgroundColor,
                    "goodOfferTitle": updatedPayload?.goodOfferTitle,
                    "goodOfferText": updatedPayload?.goodOfferText,
                    "partnerLogos": updatedPartnersLogos,
                    "removePartnerLogoIds": updatedPayload?.removePartnerLogoIds
                }
                const response = await !editOrganization
                    ? SetupService.updateDomain(domainId || "1", payload, {})
                    : SetupService.createNewDomain(payload, {});

                response.then(() => {
                    fetchPageDetails();
                    setLoading(false);
                    handleClose(!!editOrganization);
                    setIsSubmitted(false);
                    messageApi.open({
                        type: 'success',
                        content: 'Update is succesvol.',
                        duration: 3,
                    });
                })
            }
        } catch (error) {
            console.error(error);
            messageApi.open({
                type: 'success',
                content: `Failed ${error}`,
                duration: 3,
            });
        } finally {
            setLoading(false);
        }
    };

    return(
        <Layout backgroundColor="#ffffff">
            {contextHolder}
            <div className="md:mx-16 lg:mx-16 mac:mx-32 mt-8 bg-white mb-16 pb-8">
                <div className="flex px-12">
                    <h1 className="text-[30px] text-[#0D0D0D] font-sans font-semibold mr-5 mt-[32px]">
                        Setup
                    </h1>
                </div>

                <div className="px-12 pb-[8px] mt-[8px]">
                    <span
                        style={formStep === '1' ? { borderBottom: '3px solid', marginRight: 25 } : { color: '#00000040', marginRight: 25}}
                        className={`py-2 cursor-pointer font-sans font-semibold text-[14px] ${formStep === '1' && "primary-text primary-border"}`}
                        onClick={() => {setFormStep('1'); setProvincesList([])}}
                    >
                        Informatie
                    </span>

                    <span
                        style={formStep === '2' ? { borderBottom: '3px solid', marginRight: 15 } : { color: '#00000040', marginRight: 15}}
                        className={`py-2 cursor-pointer font-sans font-semibold text-[14px] ${formStep === '2' && "primary-text primary-border"}`}
                        onClick={() => setFormStep('2')}
                    >
                        Steden en gemeenten
                    </span>
                </div>

                {formStep === '1' && (
                    isLoading ? (
                            <div className="px-16 py-16" style={{ textAlign: 'center' }}>Laden...</div>
                    ) : (
                        <SetupPageTab1
                            handleUpdate={handleUpdate}
                            setupPayload={setupPayload}
                            handlePayloadChange={handlePayloadChange}
                            handleColorPayloadChange={handleColorPayloadChange}
                            errors={errors}
                        />
                    )
                )}

                {formStep === '2' && (
                    <SetupPageTab2
                        editOrganization={mainDomainObj?.id || "1"}
                        handleClose={handleClose}
                        setFormStep={setFormStep}
                        messageApi={messageApi}
                        provincesList={provincesList}
                        setProvincesList={setProvincesList}
                    />
                )}

            </div>
        </Layout>

    )
};

SetupPage.styles = {};

export default SetupPage;
